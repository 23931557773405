.BMButton {
    margin: auto 0 8%;
    a {
        display: inline-block;
        padding: 26px 120px;
        font-size: 24px;
        font-family: var(--monospace);
        text-transform: uppercase;
        background: black;
        color: white;
        text-decoration: none;
    }
}