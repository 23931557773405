.Slideshow {
  display: flex;
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background: black;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    max-height: 100vh;
    max-width: 100vw;
    transition: opacity 1s;
    opacity: 1;

    &.out {
      opacity: 0;
    }
  }
}

