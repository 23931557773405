.APIDown {
    .container {
        max-width: 1150px;
    }
    .errorScreenWrapper {
        margin: 33% 0 0;
        text-align: center;
    }
    .errorScreenTitle {
        font-family: var(--serif);
        font-weight: 400;
        font-size: 60px;
        span {
            font-style: italic;
        }
    }
    .reSubmitBtn {
        margin: 32px 0 0;
        background: var(--white);
        color: var(--black);
        padding: 26px 98px;
    }
    .returnHomeBtn {
        padding: 26px 75px;
    }
}