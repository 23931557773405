// Fonts..
@font-face {
    font-family: 'Graphik';
    src: url('../assets/fonts/graphik-regular-webfont.woff2') format('woff2'),
        url('../assets/fonts/graphik-regular-webfont.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Replica Mono';
    src: url('../assets/fonts/replicamonostd-webfont.woff2') format('woff2'),
        url('../assets/fonts/replicamonostd-webfont.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lyon Display';
    src: url('../assets/fonts/lyondisplay-regular-webfont.woff2') format('woff2'),
        url('../assets/fonts/lyondisplay-regular-webfont.woff') format('woff');
    font-style: normal;
}
@font-face {
    font-family: 'Lyon Display';
    src: url('../assets/fonts/lyondisplay-regularitalic-webfont.woff2') format('woff2'),
        url('../assets/fonts/lyondisplay-regularitalic-webfont.woff') format('woff');
    font-style: italic;
}