.Loading {
    .container {
        max-width: 1150px;
    }
    .textWrapper {
        margin: auto;
        padding: 8% 6%;
    }
    .loadingTitle {
        font-family: var(--sans);
        font-weight: 400;
        font-size: 90px;
        span {
            font-style: italic;
        }
    }
}