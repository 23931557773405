.TermsAndConditionsModal {
    .closeBtn {
        display: block;
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 23px;
        height: 23px;
      
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: (23px - 2px) / 2;
          left: 0;
          right: 0;
          height: 2px;
          background: #000;
          border-radius: 2px;
        }
      
        &:before {
          transform: rotate(45deg);
        }
      
        &:after {
          transform: rotate(-45deg);
        }
      
        span {
          display: block;
        } 
    }
    
    &__title {
        margin: 0 0 12px;
        font-family: var(--serif);
        font-size: 24px;
        font-weight: 400;
    }
    &__text {
        font-family: var(--sansSerif);
        font-size: 16px;
        max-height: 260px;
        overflow-y: scroll;
        h5 {
          margin: 20px 0 10px;
          font-size: 16px;
        }
        ul {
          list-style: disc;
          padding-left: 25px;
        }
    }
}
