:root {
  // Colors
  --white: #ffffff;
  --scondWhite: #F5F5F5;
  --black: #000000;
  --blue: #012169;
  --lightBg: #F7F1EA;
  --yellow: #F5E654;
  --red:#ff4242;
  
  // Text
  --serif: 'Lyon Display', serif;
  --sansSerif: 'Graphik', sans-serif;
  --monospace: 'Replica Mono', monospace;
  
  --titleFontWeight: 700;
  --titleFontSize: 50px;
  --titleLineHeight: 47.85px;
  
  --textFontWeight: 400;
  --textFontSize: 18px;
  --textLineHeight: 20px;
}

// Breakpoints.
$breakpoints: (
  mobile-xs: 280px,
  mobile-s: 350px,
  mobile-m: 400px,
  mobile-l: 460px,
  mobile-xl: 630px,
  tablet-xs: 720px,
  tablet-s: 768px,
  tablet-m: 820px,
  tablet-l: 990px,
  tablet-xl: 1024px,
  desktop-xs: 1180px,
  desktop-s: 1440px,
  desktop-m: 1680px,
  desktop-l: 1920px,
  desktop-xl: 2560px,
);

// Calc max
@mixin breakpoint($breakpoint, $direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    @if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else if $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    }
  }
}
