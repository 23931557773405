.ai-container {
  margin: 0 auto;
  width: 100vw;
  max-width: 500px;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--secondWhite);

  .ai-title {
    display: flex;
    text-align: center;
    padding-bottom: 4.5vh;
    font-size: 28px;
    font-family: var(--serif);
    font-weight: 400;
  }
  .ai-image {
    width: 83vw;
    max-width: 500px;
    height: auto;
  }
  .ai-social-medias {
    width: 50vw;
    max-width: 350px;
    height: auto;
    padding-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
