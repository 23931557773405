.timer-container,
.timer-container-change {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-bottom: 6.5%;
  margin-left: 90px;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 170px;
  border: 2px solid var(--red);
  background: rgba(0, 0, 0, 0);
  z-index: 11;
  &-change {
    border: 2px solid #AFAFAF;
    opacity: 0;
    margin-bottom: 0;
    margin-left: 0;
    height: 160px;
    width: 340px;
  }
  .timer,
  .timer-change {
    color: var(--red);
    font-size: 24px;
    padding-left: 10px;
    font-family: var(--monospace);
    &-change {
      color: #AFAFAF;
    }
  }
}
