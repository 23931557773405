.Questions {
  .container {
    max-width: 1100px;
  }
  .backBtn {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 64px;
    background: transparent;
    border: none;
    z-index: 15;
  }
  .questionIndicator {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    p {
      margin: 0 0 24px;
      text-align: center;
      font-family: var(--sansSerif);
      font-size: 24px;
      font-weight: 500;
    }
    .questionsBar {
      position: relative;
      display: inline-block;
      width: 656px;
      height: 10px;
      border-radius: 46px;
      background: var(--white);
      .questionsBarProgress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: var(--black);
        display: inline-block;
        border-radius: inherit;
      }
    }
  }
  .questionWrapper {
    position: relative;
    margin: auto 0 0;
    padding: 10% 0 0;
    text-align: center;
    .question {
      display: block;
      margin: 0 0 32px;
      color: var(--black);
      font-family: var(--serif);
      font-size: 56px;
      font-weight: 400;
      line-height: 70px;
    }
    input {
      display: inline-block;
      width: 100%;
      max-width: 700px;
      margin: 0 0 32px;
      padding: 32px;
      background: var(--white);
      font-family: var(--sansSerif);
      font-size: 36px;
      border: 2px solid #eaeaea;
      border-radius: 8px;
      text-align: center;
    }
    input::placeholder {
      color: #afafaf;
    }
    p.imagine {
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--black);
      text-decoration: underline;
      font-family: var(--sansSerif);
      font-size: 24px;
      font-weight: 500;
      &.imagineDisabled {
        pointer-events: none;
        opacity: 0.25;
      }
    }
    .questionsOptions_3 {
      max-width: 1000px;
      margin: 0 auto;
    }
    .questionsOptions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .questionOption {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 170px;
        margin: 10px;
        background: var(--white);
        border: 2px solid #eaeaea;
        border-radius: 8px;
        label {
          padding: 17% 10%;
        }
        input {
          position: absolute;
          visibility: hidden;
        }
        &.active {
          background: var(--black);
          color: var(--white);
        }
      }
      label {
        font-family: var(--sansSerif);
        font-size: 28px;
        text-align: center;
      }
    }
    .emailApprovalWrapper {
      display: flex;
      justify-content: center;
      input {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        padding: 0;
        accent-color: var(--black);
      }
      label {
        font-family: var(--sansSerif);
        font-size: 20px;
        font-weight: 600;
      }
      span {
        text-decoration: underline;
      }
    }
  }
  .sparkle {
    width: 19px;
    margin: 0 6px;
  }

  .disabledContinue {
    pointer-events: none;
    background: transparent;
    border: 2px solid #afafaf;
    color: #afafaf;
  }

  .skipButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    font-family: var(--sansSerif);
    font-size: 24px;
    font-weight: 700;
    color: var(--black);
  }

  .validationError {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    margin: 0 0 32px;
    font-family: var(--sansSerif) !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #ed1630 !important;
    text-decoration: none !important;
  }

  .withError {
    transform: translateY(20px);
  }
}
