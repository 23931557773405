.Intro {
    .introTitle {
        font-size: 120px;
        font-family: var(--sansSerif);
        text-transform: uppercase;
        font-size: 75px;
        font-weight: 300;
        line-height: 1.1;
        span {
            font-style: italic;
        }
    }
}