.Process {
    .BMHeader {
        .BMLogo {
            filter: invert(1);
        }
    }
    .BMButton {
        margin: auto 0 5%;
    }
    .timer-container {
        margin-bottom: 3%;
    }
    .textWrapper {
        margin-top: 16%;
    }
    .stepWrapper {
        margin-bottom: 5%;
        h3 {
            margin-bottom: 12px;
            font-family: var(--serif);
            font-weight: 400;
            font-size: 54px;
        }
        p {
            font-family: var(--sansSerif);
            font-size: 28px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
