.QRCode {
    .container {
        .textWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .timer-container {
        margin-bottom: 5%;
    }
    .qrTitle {
        font-family: var(--serif);
        font-size: 60px;
        font-weight: 400;
        margin: 0 0 45px;
    }
    .qrCodeImg {
        display: flex;
        justify-content: center;
        height: 280px;
        width: 280px;
        margin: 0 0 45px;
        background-color: var(--white);
    }
    p {
        font-family: var(--sansSerif);
        font-size: 36px;
        line-height: 130%;
    }
}