.NameReveal {
  .BMHeader {
    .BMLogo {
      filter: invert(1);
    }
  }
  .container {
    max-width: 100%;
    width: 100%;
  }
  main {
    background: var(--black);
  }
  .textWrapper {
    background: transparent;
    h3 {
      font-family: var(--serif);
      font-weight: 400;
      font-size: 44px;
    }
    h2 {
      margin: 20px 0 0;
      color: var(--white);
      font-family: var(--serif);
      font-weight: 400;
      font-size: 110px;
    }
    p {
      margin: 0 auto;
      font-family: var(--sansSerif);
      font-size: 36px;
      line-height: 130%;
      max-width: 745px;
      &:first-of-type {
        margin: 40px auto 0;
      }
    }
  }
  .BMButton {
    a {
      background: var(--lightBg);
      color: var(--black);
    }
  }
}
