.generating-container {
  .image-background {
    height: 100svh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    .mesage-container {
      background-color: black;
      color: white;
      height: 25vh;
      width: 74vw;
      max-width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .message {
        font-size: 32px;
        line-height: 94%;
        width: auto;
        height: auto;
        text-align: center;
        padding-bottom: 8%;
        font-family: var(--serif);
        font-weight: 400;
        .world {
          font-style: italic;
        }
      }
      .time-message {
        font-size: 12px;
        line-height: 94%;
        width: auto;
        height: auto;
        text-align: center;
        font-family: var(--sansSerif);
      }
    }
  }
}
