*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: var(--fontFamily);
  // font-size: var(--textFontSize);
  // font-weight: var(--textFontWeight);
  // line-height: var(--mobileTextLineHeight);
  -webkit-font-smoothing: antialiased;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
}
*input {
  user-select: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -o-user-select: auto;
}

main {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--lightBg);
}

.container {
  position: relative;
  height: 100%;
  width: 90%;
  max-width: 850px;
  margin: 0 auto;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ImageBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.textWrapper {
  margin-top: auto;
  background: var(--black);
  padding: 8% 10%;
  color: white;
  text-align: center;
}
