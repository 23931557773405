.RecordAdmin {
  background: white;
  width: 100%;
  font-size: 1rem;

  .record {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr  1fr 1fr;
    padding: 1rem 0;
    border: 1px solid gray;

    &.error {
      background: #f8d7da;
    }

    button {
      max-width: 75px;
    }
  }
}

