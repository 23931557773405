.PromptGenerator {
  background: white;
  width: 100%;
  font-size: 1rem;

  div {
    padding: 1rem;
  }

  input {
    width: 100%;
  }

  .prompt {
    display: grid;
    grid-template-columns: 500px 500px 500px 90px 1fr;
    padding: 1rem 0;

    user-select: all;

    &.error {
      background: #f8d7da;
    }
  }
}

